import React from 'react'
import '../App.css'
import './HeaderSection.css'


function HeaderSection(props) {
    return (
        <div className={props.cName}>
            

            <div className='header-text'>
                <h1>{props.title}</h1>
                <p>{props.text}</p>
                <a href={props.url} className={props.btnClass}>
                    {props.buttonText}
                </a>
            </div>
            
            
        </div>
    )
}

export default HeaderSection
