import React from 'react';
import { Link } from 'react-router-dom'
import Button from './Button'
import './Footer.css'
import { useTranslation } from 'react-i18next'
 
 


function Footer() {

    const {t} = useTranslation();
   

    return (
        <>
            <div className='footer-container'>
                <section className="footer-subscription">
                   
                    <p className="footer-subscription-text">
                                          </p>
                    <div className='header-btns'>
                        <Button
                            className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--large'
                        >
                           {t('navbar.estimate')}
                        </Button>
                    </div>
                </section>
                <div className="footer-links">
                    {/* <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2><Link to='/about'> {t('navbar.about')}</Link></h2>
                            <Link to='/about'>{t('aboutus.mission')}</Link>
                            <Link to='/about'>{t('aboutus.vision')}</Link>
                            <Link to='/privacy-statement'>{t('aboutus.privacy')}</Link>
 
                          

                        </div>
                    </div> */}
                    <div className='footer-link-wrapper'>

                        <div className='footer-link-items'>
                            <h2><Link to='/contact'> {t('navbar.contact')}</Link></h2>
                            <p>Ivar Aasensvei 17,</p>
                            <p>0373, Oslo Norway</p>
                            <p><a href='mailto:kontakt@godmat-retthjem.no'>kontakt@godmat-retthjem.no</a></p>
                            <p><a href='tel:+4798612396'> +4798612396</a></p>
                            <p><a href='https://data.brreg.no/enhetsregisteret/oppslag/enheter/992631481'>Bryhni.com AS</a></p>
                            <Link to='/privacy-statement'>{t('footer.privacy')}</Link>

                        </div>
                        <div class='footer-link-items'>
                            <h2>{t('footer.payment')}</h2>
                            <Link to='/' className='payment-icons'>
                            <img src="images/card.png" />
                            </Link>
                            <Link to='/' className='payment-icons'>
                            <img src="images/faktura.png" />
                            </Link>
                            <Link to='/' className='payment-icons'>
                            <img src="images/vipps.png" />
                            </Link>
                            
                        </div>
                    </div>

                </div>
                <section class='social-media'>
                    <div class='social-media-wrap'>
                        
                        <small class='payment-icons'>
                        <img src='images/icons/call.png'/>
                        <a href='tel:+4796683122'> +4796683122</a>
                        </small>
                        
                        <small class='payment-icons'>
                        <img src='images/icons/email.png'/>
                        <a href='mailto:kontakt@godmat-retthjem.no'> kontakt@godmat-retthjem.no </a>
                        </small>
                         
                         
                    </div>
                </section>

            </div>
        </>
    )

}

export default Footer