import React from 'react'
import HeaderSection from '../HeaderSection'
import '../Cards.css'
import CardItem from '../CardItem'
import { useTranslation } from 'react-i18next'


function About() {

  const {t} = useTranslation();

  return (
    <>
     <HeaderSection
        cName='header-container'
        //headerImg=''
        title= {t('about.who')}
        // text= {t('navbar.smaker')}
        buttonText=  {t('navbar.texts')}
        // url='/'
        // btnClass='show'
      />

      <div className='cards'>
    
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <p>{t('about.origin')}</p> <br/>
                    <p>{t('about.mission')}</p> <br/>
                    <p>{t('about.taste')}</p> <br/>
                    <p>{t('about.cater')}</p>
                    <img src="images/anonse/anonse.png" class="img-fluid" />

                </div>

            </div>
        </div>


    </>
  )
}

export default About
