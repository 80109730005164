import React from 'react'
import HeaderSection from '../HeaderSection'
import InquiryMessage from '../InquiryMessage'
import { useTranslation } from 'react-i18next'


function InquireNow() {
  const {t} = useTranslation();

  return (
    <>
        <HeaderSection
        cName='header-container'
        headerImg='images/header.png'
        title={t('navbar.title')}
        text= {t('navbar.smaker')}
        buttonText=  {t('navbar.texts')}
        // url='/'
        // btnClass='show'
      />
      <InquiryMessage/>
    </>
  )
}

export default InquireNow
