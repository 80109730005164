import React from 'react'
import HeaderSection from '../HeaderSection'
import '../../App.css'
import Cards from '../Cards'
import FoodD from './FoodDelivery'
import { useTranslation } from 'react-i18next'

 function Home() {
  const {t} = useTranslation();

  return (
    <>
      <HeaderSection
        cName='header-container'
        headerImg='public/images/header.png'
        title={t('navbar.title')}
        text= {t('navbar.smaker')}
        buttonText=  {t('navbar.texts')}
      //  url='/'
      //  btnClass='show'
      />
      <FoodD/>
    </>
  )
}

export default Home
