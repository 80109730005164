import React from 'react'
import HeaderSection from '../HeaderSection'
import { Link } from 'react-router-dom'
import '../Cards.css'
import '../Contact.css'
import ReviewItem from '../ReviewItem'
import { useTranslation } from 'react-i18next'


function Feedback() {
    const {t} = useTranslation();

  return (
    <>
         <HeaderSection
        cName='header-container'
        //headerImg=''
        title={t('navbar.title')}
        text= {t('navbar.smaker')}
        buttonText=  {t('navbar.texts')}
        // url='/'
        // btnClass='show'
      />

<div className='cards'>
            <h1>{t('feedback.review')}</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <ReviewItem
                            paragraph= {t('feedback.f1text')}
                            text=''
                            date='April 2024'
                        />
                        <ReviewItem
                            paragraph= {t('feedback.f2text')}
                            text=''
                            date='April 2024'
                        />
                    </ul>
                
                  
                 
                   
                </div>
            </div>   
        </div>


    </>
  )
}

export default Feedback
