import React from 'react';
import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Components/pages/Home'
import Footer from './Components/Footer'
import About from './Components/pages/About';
import Contact from './Components/pages/Contact';
import InquireNow from './Components/pages/InquireNow';
import Feedback from './Components/pages/Feedback';
import ScrollToTop from './Components/ScrollToTop';
import FoodD from './Components/pages/FoodDelivery';
import PrivacyStatement from './Components/pages/PrivacyStatement';
import Menus from './Components/pages/Menus';
  
 

function App() {
  return (

    <BrowserRouter>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/inquire-now' element={<InquireNow />} />
        <Route path='/food-delivery' element={<FoodD/>} />
        <Route path='/menus' element={<Menus/>} />
        <Route path='/privacy-statement' element={<PrivacyStatement/>} />

        <Route path='*' element={
              <>
               <img src='../images/oops.jpg'/>
              </>
            }
            />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
