import React from 'react'
import HeaderSection from '../HeaderSection'
import { Link } from 'react-router-dom'
import '../Cards.css'
import '../Contact.css'
import CardItem from '../CardItem'
import { useTranslation } from 'react-i18next'



function Contact() {
  const {t} = useTranslation();

  return (
    <>
        <HeaderSection
        cName='header-container'
        //headerImg=''
        title={t('navbar.contact')}
        text=''
        buttonText=  {t('navbar.texts')}
        // url='/'
        // btnClass='show'
      />

      <div className='cards'>
    
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>

                <div>
                  
           <table className='contact-table'>

            <tr>
              <h5>
              God mat - Rett hjem
              </h5>
            </tr>
            <tr>
              <td>Ivar Aasens Vei 17, Oslo Norway.</td>
            </tr>
            <tr>
              <td>
                <a href='mailto:kontakt@godmat-retthjem.no'> kontakt@godmat-retthjem.no </a>
                </td>
            </tr>
            <tr>
              <td>
                <a href='tel:+4798612396'>+4798612396 </a></td>
            </tr>
            <tr>
              <td>
               <Link
                class='social-icon-link facebook'
                to='https://www.facebook.com/GodmatRetthjem'
                target='_blank'
                aria-label='Facebook'
              >
                <i class='fab fa-facebook-f'>acebook</i>
              </Link>
              </td>
             </tr>

          </table>
       
        </div>
        <img src="images/anonse/bbq.png" class="img-fluid" />

               
            </ul>  
                    
                
                </div>
             </div>
        </div>





    </>
  )
}

export default Contact
