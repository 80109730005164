import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./Button.css"

const languages = [
    {code: "no", lang: <img src="images/flag/norway-flag.png" class="img-fluid" />},
    {code: "en", lang: <img src="images/flag/uk-flag.png" class="img-fluid" />},
  
];

const LanguageSelector = (props) => {
  const {i18n} = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="lang">
      {languages.map((lng) => {
        return (
          <span
            onChange={props.onCha}
            className={lng.code === i18n.language ? "selected" : ""}
            key={lng.code}
            onClick={() => changeLanguage(lng.code)}
          >
            {lng.lang}
          </span>
        );
      })}
    </div>
  );
};

export default LanguageSelector;