import React, { useEffect, useState } from 'react'
import '../Cards.css'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import { useTranslation } from 'react-i18next'
import CardItemFood from '../CardItemFood'


 
export const Menus = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();


    return (
        <>
         <HeaderSection
        cName='header-container'
        //headerImg=''
        title={t('food-menu.h1')}
        text=''
        buttonText={t('food-menu.h3')} 
        // url='/'
        // btnClass='show'
      />
           
    
        <div className='service-offer'>
        <div className='cards'>
                <div className='cards__container'>
                    <div className='cards__wrapper'>

                        <h4>{t('food-menu.weekone')}</h4>

                   
                        <ul className='cards__items'> 
                             <CardItemFood
                                src='images/FoodDelivery/fish-curry.jpeg'
                                text={t('food-menu.fish-curry')}
                                />     
                      
                     
                            <CardItemFood
                                src='images/FoodDelivery/chicken-teriyaki.jpg'
                                text={t('food-menu.chicken-teriyaki')}

                            />
 
                            <CardItemFood
                                src='images/FoodDelivery/pork-adobo.jpeg'
                                text= {t('food-menu.pork-adobo')}
                            />
                            <CardItemFood
                                src='images/FoodDelivery/tomato-soup.jpg'
                                text={t('food-menu.tomato-soup')}
                            />  
                            <CardItemFood
                                src='images/FoodDelivery/sweet-sour.jpg'
                                text={t('food-menu.sweet-sour')}
                             />  

                        </ul>
                     
                        <h4>{t('food-menu.weektwo')}</h4>

                        <ul className='cards__items'>
                            <CardItemFood
                                src='images/FoodDelivery/eggplant-lasagne.jpeg'
                                text= {t('food-menu.lasagne')}
                            />
                                <CardItemFood
                                    src='images/FoodDelivery/ceasar-salat.jpeg'
                                    text={t('food-menu.ceasar-salat')}
                                />  
                            <CardItemFood
                                src='images/FoodDelivery/chicken-teriyaki.jpg'
                                text={t('food-menu.chicken-teriyaki')}
                            />  
                            <CardItemFood
                                src='images/FoodDelivery/sweet-sour.jpg'
                                text={t('food-menu.sweet-sour')}
                            />
                            <CardItemFood
                                src='images/FoodDelivery/roasted.jpeg'
                                text={t('food-menu.roasted')}
                            />  
                        </ul>

                        <h4>{t('food-menu.weekthree')}</h4>

                        <ul className='cards__items'>
                            <CardItemFood
                                src='images/FoodDelivery/pineapple-chicken.jpeg'
                                text= {t('food-menu.pineapple-chicken')}
                            />
                            <CardItemFood
                                src='images/FoodDelivery/carbonara.jpeg'
                                text={t('food-menu.spaghetti-carbonara')}
                            />  
                            
                            <CardItemFood
                                src='images/FoodDelivery/chicken-curry.jpeg'
                                text={t('food-menu.chicken-curry')}
                            />  
                            <CardItemFood
                                src='images/FoodDelivery/pork-adobo.jpeg'
                                text= {t('food-menu.pork-adobo')}
                            /> 
                             <CardItemFood
                                src='images/FoodDelivery/sweet-sour.jpg'
                                text={t('food-menu.sweet-sour')}
                             />
                        </ul>

                        <h4>{t('food-menu.weekfour')}</h4>

                        <ul className='cards__items'>
                            <CardItemFood
                                src='images/FoodDelivery/sweet-sour.jpg'
                                text={t('food-menu.sweet-sour')}
                             />
                             <CardItemFood
                                    src='images/FoodDelivery/ceasar-salat.jpeg'
                                    text={t('food-menu.ceasar-salat')}
                            />  
                            <CardItemFood
                                src='images/FoodDelivery/tomato-soup.jpg'
                                text={t('food-menu.tomato-soup')}
                            />  
                             <CardItemFood
                                src='images/FoodDelivery/fish-curry.jpeg'
                                text={t('food-menu.fish-curry')}
                                />  
                             <CardItemFood
                                src='images/FoodDelivery/eggplant-lasagne.jpeg'
                                text= {t('food-menu.lasagne')}
                            />
                        </ul>     

                         <h4>{t('food-menu.catering')}</h4>

                        <ul className='cards__items'>
                            <CardItemFood
                                src='images/Catering/beef-creamy.jpg'
                                text={t('food-menu.beef-creamy')}
                             />
                             <CardItemFood
                                    src='images/Catering/chicken-noodles.jpg'
                                    text={t('food-menu.chicken-noodles')}
                            />  
                        </ul>
                        <ul className='cards__items'>
                            <CardItemFood
                                src='images/Catering/beef-steak.jpg'
                                text={t('food-menu.beef-steak')}
                            />  
                             <CardItemFood
                                src='images/Catering/sweet-sour-chicken.jpg'
                                text={t('food-menu.sweet-sour-chicken')}
                                />  
                        </ul>                 
                        
                        <ul className='cards__items'>
                            <CardItemFood
                                src='images/Catering/springrolls.jpg'
                                text={t('food-menu.springrolls')}
                             />
                             <CardItemFood
                                    src='images/Catering/pad-thai.jpg'
                                    text={t('food-menu.pad-thai')}
                            />  
                         </ul>
                         <ul className='cards__items'>   
                            <CardItemFood
                                src='images/Catering/kare-kare.jpg'
                                text={t('food-menu.kare-kare')}
                            />  
                             <CardItemFood
                                src='images/Catering/chicken-chopsuey.jpg'
                                text={t('food-menu.chicken-chopsuey')}
                            />  
                        </ul> 
                    </div>
                </div>
 
        </div>
        


            <p> {t('food-menu.p1')}</p>

        </div>
        
    </>
    )
}
export default Menus
