import React, { useRef, useState } from 'react';
import emailjs, { init, sendForm } from '@emailjs/browser';
import './InquiryMessage.css'
import Validation from './Validation';
import Modal from './Modal';
import { useTranslation } from 'react-i18next'



function InquiryMessage() {
  const {t} = useTranslation();

  const [openModal, setOpenModal] = useState(false)

  const [values, setValues] = useState({
    fname: '',
    lname: '',
    phonenumber: '',
    address: '',
    apartmentsize: '',
    email: '',
    services: '',
    // agree: '',
    message: ''

  })


  const [errors, setErrors] = useState({})

  function handleInput(event) {
    const newObj = { ...values, [event.target.name]: event.target.value }
    setValues(newObj)
  }



  init('WtPICQ5tEHY3zuZzn')
  const form = useRef();

  const sendEmail = (e) => {

    e.preventDefault();

    //setErrors(Validation(values))

    emailjs.sendForm('service_8ztazij', 'template_irue2sm', form.current, 'WtPICQ5tEHY3zuZzn')
      .then((result) => {
        alert('Message Sent Successfully!');
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }




  return (

    <>
      {openModal && <Modal closeModal={setOpenModal} />}

      <div className='form-container'>
        <h1 className='form-title'>{t('request.h1')}</h1>
        <p>{t('request.p')}</p>
        <hr/>
        <form id='form' onSubmit={sendEmail} ref={form}>
          <div className='main-input'>
            <div className='inputbox'>
              {errors.fname && <p style={{ color: 'red' }}>{errors.fname}</p>}
              <input
                id='fname'
                name='fname'
                type='text'
                placeholder={t('request.firstname')}
                onChange={handleInput} required />
            </div>
            <div className='inputbox'>
              {errors.lname && <p style={{ color: 'red' }}>{errors.lname}</p>}
              <input
                id='lname'
                name='lname'
                type='text'
                placeholder={t('request.lastname')}
                onChange={handleInput} />
            </div>
            <div className='inputbox'>
              {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
              <input
                id='email'
                name='email'
                type='email'
                placeholder={t('request.email')}
                onChange={handleInput} required />
            </div>
            <div className='inputbox'>
              {errors.phonenumber && <p style={{ color: 'red' }}>{errors.phonenumber}</p>}
              <input
                id='phonenumber'
                name='phonenumber'
                type='phone'
                placeholder={t('request.number')}
                onChange={handleInput} />
            </div>
            <div className='inputbox'>
              {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
              <input
                id='address'
                name='address'
                type='text'
                placeholder={t('request.address')}
                onChange={handleInput} required />
            </div>
            <div className='inputbox'>
              {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
              <input
                id='street'
                name='street'
                type='text'
                placeholder={t('request.street')}
                onChange={handleInput} required />
            </div>
            <div className='inputbox'>
              {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
              <input
                id='postoffice'
                name='postoffice'
                type='text'
                placeholder={t('request.postoffice')}
                onChange={handleInput} required />
            </div>
            <div className='inputbox'>
              {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
              <input
                id='postnumber'
                name='postnumber'
                type='text'
                placeholder={t('request.postnumber')}
                onChange={handleInput} required />
            </div>
          </div>

          <div className='main-services'>
            <div className='services-input'>
              {errors.services && <p style={{ color: 'red' }}>{errors.services}</p>}
              <h3 className='services-title'>{t('request.which')}</h3>
             
              <select
                id='services'
                name='services'
                onChange={handleInput} required>
                <option value=''>{t('request.choose')}</option>
                <option value='Inquiries'>{t('request.inquiries')}</option>
                <option value='Catering'>{t('request.catering')}</option> 
                <option value='Weekly food delivery'>{t('request.food-delivery')}</option>
            </select>
            <br/>
            <br/>
                <br/>
            <select
                id='people'
                name='people'
                onChange={handleInput} >
                <option value=''>{t('request.people')}</option>
                <option value='2'>2</option>
                <option value='4'>4</option> 
                <option value='6'>6</option>
                <option value='7+'>7+</option>
            </select>
          
            <br/>
            <br/>
              <br/>
            <select
                id='dinner'
                name='dinner'
                onChange={handleInput} >
                <option value=''>{t('request.dinner')}</option>
                <option value='3'>3</option>
                <option value='4'>4</option> 
                <option value='5'>5</option>
            </select>
            </div>
          </div>
 

          <div className='main-message'>
            <div className='message-input'>
              {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}
              <textarea
                id='message'
                name='message'
                placeholder={t('request.message')} rows='10'
                onChange={handleInput} required>
              </textarea>
            </div>
          </div>

          <div className='main-terms'>
            {/* <div className='terms-input'>
              {errors.agree && <p style={{ color: 'red' }}>{errors.agree}</p>}
              <input class="acceptterms" type="checkbox" name="agree" id='agree' value="agree_terms" required/>
              <button
                className='openModalBtn'
                onClick={() => {
                  setOpenModal(true)
                }}
              > I agree to the Terms and Conditions</button>
            </div> */}
          </div>
          <div className='form-submit-btn'>
            <input type='submit' value={t('request.send')}></input>
          </div>

        </form >
      </div >
    </>
  )
}

export default InquiryMessage
