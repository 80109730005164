import React, { useEffect, useState } from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import '../ServiceOffer.css'
import { useTranslation } from 'react-i18next'

const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const FoodD = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>
        
                  
                <div className='service-offer'>
                        {/* <h1>{t('food-delivery.h1')}</h1> */}
    

                    <div className='first-service'>
                        <div className='first-service-text'>
                            <h2>{t('food-delivery.h1')}</h2>
                            <p> 
                                {t('food-delivery.p1')}
                            </p>
                            <p> {t('food-delivery.p2')}</p>
                        </div>

                        <div className='image'>
                            <img alt='' src='images/FoodDelivery/chicken-teriyaki.jpg' />
                            <img alt=''  src='images/FoodDelivery/tomato-soup.jpg' />
                        </div>

                  </div>

                    <div className='first-service-reverse'>
                        <div className='first-service-text'>
                             <p> <i>{t('food-delivery.p3')}</i> </p>
                            <p>{t('food-delivery.p4')}</p>
                        </div>
                        <div className='image'>
                                <img alt='' src='images/FoodDelivery/food-delivery3.JPG' />
                                <img alt='' src='images/FoodDelivery/sweet-sour.jpg' />
                        </div>
                
                    </div>

                    <div className='first-service'>
                        <div className='first-service-text'>
                            <h2>{t('food-delivery.h2')} </h2>
                            <p>{t('food-delivery.p7')} </p>
                            <p>{t('food-delivery.p8')} </p>
                        </div>
                        <div className='image'>
                                <img alt='' src='images/FoodDelivery/eggplant-lasagne.jpeg' />
                                <img alt='' src='images/FoodDelivery/roasted.jpeg' />
                        </div>
                        
                    </div>
                             
                </div>
          

        </>
    )
}

export default FoodD
